import { i18n as $ } from "../locale/index.js";

export const validations = {
  sendAmtValidation: [
    (v) => !!v || $.t("sendAmtMsg1"),
    (v) => +v > 0 || $.t("sendAmtMsg1"),
    (v) => /^[0-9]\d{0,9}(\.\d{1,3})?%?$/.test(v) || $.t("sendAmtMsg2"),
  ],
  descriptionVlaidation: [
    (v) => v.length > 10 || "Description must be more than 10 charecters.",
  ],
  EvoletAmtValidation: [
    (v) => !!v || $.t("sendAmtMsg1"),
    (v) => v > 0 || $.t("sendAmtMsg2"),
    (v) => +v > 9 || "Sending Amount must be more than RM10.",
    (v) => +v <= 5000 || "Sending Amount must be less than RM5,000.",
    (v) => /^[0-9]\d{0,9}(\.\d{1,3})?%?$/.test(v) || $.t("sendAmtMsg2"),
  ],
  paymentMode: [(v) => !!v || $.t("paymentMsg1")],
  reciveAmtValidation: [(v) => v >= 0 || $.t("reciveAmtMsg2")],
  firstnameValidations: [
    (v) => !!v || $.t("firstNameMsg1"),
    (v) => /^([a-zA-Z]+\s?)*$/.test(v) || $.t("firstNameMsg2"),
  ],
  lastnameValidations: [
    (v) => !!v || $.t("lastNameMsg1"),
    (v) => /^([a-zA-Z]+\s?)*$/.test(v) || $.t("lastNameMsg2"),
  ],
  dobValidations: [(v) => !!v || $.t("dobMsg1")],
  genderValidations: [(v) => !!v || $.t("genderMsg1")],
  nationalityValidations: [(v) => !!v || $.t("nationalityMsg1")],
  OcupationValidations: [
    (v) => !!v || $.t("occupationMsg1"),
    (v) => /^([a-zA-Z]+\s?)*$/.test(v) || $.t("occupationMsg2"),
  ],
  emailValidations: [
    (v) => !!v || $.t("emailMsg1"),
    (v) =>
      /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(v) || $.t("emailMsg2"),
  ],
  mobileRules: [
    (v) => !!v || $.t("mobile_required"),
    (v) => /^(([0-9]*){20})$/.test(v) || $.t("mobile_required"),

    (v) => (v && v.length >= 9) || $.t("min_mobile_number"),
    (v) => (v && v.length <= 12) || $.t("max_mobile_number"),
  ],
  postalcodeValidations: [
    (v) => !!v || $.t("postalMsg1"),
    (v) => /^[0-9]+$/.test(v) || $.t("postalMsg2"),
  ],
  walletRules: [(v) => /^[0-9]+$/.test(v) || $.t("walletMsg1")],
  addressValidations: [
    (v) => !!v || $.t("addressMsg1"),
    (v) => v.length < 98 || "Address must be less than 100 characters",
  ],
  phonenumberValidations: [(v) => !!v || $.t("phoneMsg1")],
  reasonValidations: [(v) => !!v || $.t("reasonMsg1")],
  paymenttypeValidateions: [(v) => !!v || $.t("paymentTypeMsg1")],
  remarksValidations: [
    (v) => !!v || $.t("remarkMsg1"),
    // (v) => /^([a-zA-Z]+\s?)*$/.test(v) || $.t('remarkMsg2'),
  ],
  empoyerNameValidations: [(v) => !!v || $.t("Enter_empoyer_name")],
  sourceoffundsValidation: [(v) => !!v || $.t("sourceMsg1")],
  bankadviceValidations: [
    (v) => !!v || $.t("bankAdviceMsg1"),
    // (v) => v.size <= 5000000 || $.t('bankAdviceMsg2')
  ],
  firstPageValidations: [(v) => !!v || $.t("frontpage")],
  idtypeValidations: [(v) => !!v || $.t("idtypeMsg1")],
  idnumberValidations: [(v) => !!v || $.t("idnumberMsg1")],
  issuedateValidations: [(v) => !!v || $.t("issueDateMsg1")],
  issuelocationValidations: [
    (v) => !!v || $.t("issueLocationMsg1"),
    (v) => /^([a-zA-Z]+\s?)*$/.test(v) || $.t("issueLocationMsg2"),
  ],
  expirydateValidations: [(v) => !!v || $.t("expiryDateMsg1")],
};
export const FieldRequired = (val) => {
  return [(v) => !!v || `${val} is required`];
};
