<template>
  <v-app class="grey lighten-5">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <!-- <NavBar /> -->
      <v-card
        elevation="0"
        rounded="lg"
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="p-4 main-content-div login-main-div">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="image-div">
              <h2 class="text-primary-color">{{ $t("appName") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/mobile_payments.png"
                style="max-height: 75% !important"
              />
            </v-col>
            <v-col cols="12" sm="12" lg="6" class="pb-2">
              <v-form v-model="valid" ref="form">
                <div class="w-100 m-auto p-5 main-text">
                  <h2 class="text-center text-primary-color font-weight-bold">
                    {{ $t("userRegistraion") }}
                  </h2>
                  <loginTypeVue @selectedLogin="selectedLogin" />
                  <br />

                  <label class="text-midgray-color">{{
                    login_type === "mobile"
                      ? $t("confirm_mobile_num")
                      : $t("confirm_email")
                  }}</label>
                  <v-select
                    v-model="user_type"
                    :items="user_types"
                    :placeholder="$t('user_type_placeholder')"
                    :label="$t('user_type')"
                    item-text="txt"
                    item-value="val"
                    :rules="user_type_rules"
                  >
                    <template v-slot:prepend-inner class="pt-0">
                      <v-icon class="me-4 pb-2 font-size">{{
                        user_type === "IND"
                          ? "mdi-account-tie"
                          : "mdi-briefcase-account"
                      }}</v-icon>
                    </template></v-select
                  >

                  <v-text-field
                    v-if="login_type === 'mobile'"
                    type="number"
                    hide-spin-buttons
                    id="sendAmount"
                    v-model="mobile_number"
                    :hint="'+61 xx xxxx xxxx'"
                    v-mask="'##############'"
                    color="primary"
                    prefix="+61"
                    :placeholder="$t('mobile_number_placeholder')"
                    required
                    :rules="mobileRules"
                    class="custome-text-field"
                    :label="$t('mobile_number')"
                    @keydown="enterKeyPressed"
                  >
                    <template v-slot:prepend-inner class="pt-0">
                      <country-flag country="AU" class="mb-0 me-2" />
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="email"
                    :label="$t('emailID')"
                    :placeholder="$t('email_placeholder')"
                    :rules="emailValidations"
                    clearable
                    append-icon="mdi-account-outline"
                  ></v-text-field>
                  <div
                    class="m-auto position-relative"
                    style="max-width: 300px"
                  >
                    <v-otp-input
                      length="6"
                      type="number"
                      v-model="otpEntered"
                      @finish="onOTPUpdate"
                      :disabled="isOTPLoading"
                      class="font-weight-bold"
                      v-if="isOTPEnable"
                    ></v-otp-input>

                    <h6 class="text-dark" v-if="timerCount > 0 && isOTPEnable">
                      {{ $t("otp_expired") }}
                      <span class="font-weight-bold">{{ timerCount }}</span>
                      {{ $t("seconds") }}
                    </h6>
                    <div
                      class="text-center d-flex justify-content-center align-items-baseline"
                      v-if="timerCount === 0 && isOTPEnable"
                    >
                      <span class="text-dark"
                        >{{ $t("did_not_receive_the_otp") }}?</span
                      >
                      <v-btn
                        class="text-capitalize"
                        color="primary"
                        @click="getOTPCode"
                        text
                        >{{ $t("resend_otp") }}</v-btn
                      >
                    </div>
                  </div>

                  <v-overlay absolute :value="isOTPLoading">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-overlay>

                  <v-btn
                    block
                    rounded
                    color="primary"
                    @click="onSubmitVerify"
                    class="btn-primary mt-3 text-capitalize"
                    :disabled="isOTPEnable"
                    v-if="!isOTPEnable"
                  >
                    <div class="button-loader" v-if="loader">
                      <span class="spinner-border spinner-border-sm"></span>
                    </div>
                    <div v-else>{{ $t("verify_otp") }}</div>
                  </v-btn>

                  <div
                    class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                  >
                    <span>{{ $t("back_to") }}</span>
                    <v-btn
                      class="text-capitalize text-primary-color p-2"
                      color="primary"
                      plain
                      @click="$router.push('/login')"
                    >
                      {{ $t("loginPage") }}?
                    </v-btn>
                  </div>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>
  </v-app>
</template>

<script>
import { webbreakdown } from "../../utils/breakpoints";
import { returnuserTypes } from "../../utils/constDropdowns/userType";
import { i18n as $ } from "../../locale/index";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
import loginTypeVue from "./loginType.vue";
import { validations } from "@/utils/validation";
const { mobileRules, emailValidations } = validations;
export default {
  name: "SignUp",
  mixins: [alerts],
  components: {
    loginTypeVue,
  },
  data() {
    return {
      webbreakdown,
      mobile_number: "",
      login_type: "email",
      email: "",
      user_type: "BUS",
      user_types: returnuserTypes(),
      loader: false,
      dismissCountDown: 20,
      countDownAlert: 0,
      onCheckFlag: false,
      showError: false,
      otpEntered: 0,
      isOTPLoading: false,
      isOTPEnable: false,
      timerCount: 0,
      mobileRules,
      emailValidations,
      user_type_rules: [(v) => !!v || $.t("usertype_req")],
    };
  },

  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && this.isOTPEnable) {
          // Time interval to run every 1 sec.
          setTimeout(() => {
            // stop interval when time out reaches to zero.
            if (this.timerCount === 0) return;
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectedLogin(data) {
      this.login_type = data;
    },
    enterKeyPressed(event) {
      if (event.keyCode == 13) this.onSubmitVerify();
    },
    checkNumber(val) {
      if (val.substring(0, 2) === "61") {
        return val;
      } else {
        return "61" + val;
      }
    },
    async getOTPCode(selected_user_name) {
      try {
        let res;
        this.login_type === "mobile"
          ? (res = await userservice.get_otpMobile({
              mobile_number: selected_user_name,
              user_type: this.user_type,
            }))
          : (res = await userservice.get_otpEmail({
              user_email: selected_user_name,
              user_type: this.user_type,
            }));
        if (res && res.data.success) {
          this.timerCount = 5 * 60;
          this.showAlertSuccess(res.data.message);
        } else {
          this.showAlertError(res.data.message);
        }
        return res;
      } catch (err) {
        this.showAlertError("something went wrong please try again sometime!");
        return err;
      }
    },
    async onSubmitVerify() {
      if (this.$refs.form.validate()) {
        try {
          this.loader = true;
          let responseData;
          let selected_user_name;
          if (this.login_type === "mobile") {
            const formatNum = this.checkNumber(this.mobile_number);
            responseData = await userservice.registerMobile({
              mobile_number: formatNum,
              user_type: this.user_type,
            });
            selected_user_name = formatNum;
            sessionStorage.setItem("mobile_number", formatNum);
            this.$store.commit("mobile_number", formatNum);
          } else {
            responseData = await userservice.registerEmail({
              user_email: this.email,
              user_type: this.user_type,
            });
            selected_user_name = this.email;
            sessionStorage.setItem("email", this.email);
          }
          if (responseData && responseData.data.success) {
            this.loader = false;
            this.showAlertSuccess(responseData.data.message);
            sessionStorage.setItem("selected_user_type", this.user_type);
            sessionStorage.setItem("selected_user_name", selected_user_name);
            this.isOTPEnable = true;
            await this.getOTPCode(selected_user_name);
          } else {
            this.loader = false;
            this.showAlertError(responseData.data.message);
          }
        } catch (error) {
          this.loader = false;
          this.showAlertError(
            "something went wrong please try again sometime!"
          );
        }
      }
    },
    async onOTPUpdate(otpEntered) {
      this.isOTPLoading = true;
      try {
        let res;
        const selected_user_name =
          this.login_type === "mobile"
            ? this.checkNumber(this.mobile_number)
            : this.email;
        this.login_type === "mobile"
          ? (res = await userservice.confirm_otp_mobile({
              mobile_number: this.checkNumber(this.mobile_number),
              otp: otpEntered,
            }))
          : (res = await userservice.confirm_otp_email({
              user_email: this.email,
              otp: otpEntered,
            }));
        if (res && res.data.success) {
          this.showAlertSuccess(res.data.message);
          this.isOTPLoading = false;
          this.isOTPEnable = false;
          setTimeout(
            () =>
              this.$router.push({
                name: "Registration",
                query: {
                  login_type: this.login_type,
                  user_name: selected_user_name,
                },
              }),
            500
          );
        } else {
          this.isOTPLoading = false;
          this.isOTPEnable = true;
          this.showAlertError(res.data.message);
        }
      } catch (err) {
        console.error(`confirm-otp error: ${err}`);

        this.isOTPLoading = false;
        this.isOTPEnable = true;
        this.showAlertError("something went wrong please try again sometime!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";

.custom-select {
  margin-top: 18px;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
::v-deep ul {
  list-style-type: none !important;
}
.card-title {
  color: $dimgrey;
}
.card-text {
  color: $lightslategrey;
}
label {
  color: $lightslategrey;
}
.logo_img {
  max-height: 70px;
}
.div-signup {
  color: $primary;
  cursor: pointer;
}
::v-deep .btn-light {
  border: 1px solid #d3d3d3 !important;
}
.card {
  border: none;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}
.font-size {
  font-size: 28px;
}
.label-policy {
  display: flex;
}
</style>
